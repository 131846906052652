import { getFormProps, getInputProps, useForm } from '@conform-to/react'
import { getZodConstraint, parseWithZod } from '@conform-to/zod'
import {
	json,
	type ActionFunctionArgs,
	type LoaderFunctionArgs,
	type MetaFunction,
} from '@remix-run/node'
import { Form, useActionData, useSearchParams } from '@remix-run/react'
import { HoneypotInputs } from 'remix-utils/honeypot/react'
import { z } from 'zod'
import { GeneralErrorBoundary } from '#app/components/error-boundary.tsx'
import { CheckboxField, ErrorList, Field } from '#app/components/forms.tsx'
import { Logo } from '#app/components/logo.js'
import { Spacer } from '#app/components/spacer.tsx'
import {
	Card,
	CardContent,
	CardDescription,
	CardHeader,
	CardTitle,
} from '#app/components/ui/card.js'
import { StatusButton } from '#app/components/ui/status-button.tsx'
import {
	loginWithEmail,
	// requireAnonymous,
	requireFamilyAnonymous,
} from '#app/utils/auth.server.ts'
import { checkHoneypot } from '#app/utils/honeypot.server.ts'
import { useIsPending } from '#app/utils/misc.tsx'
import { EmailSchema } from '#app/utils/user-validation.ts'
import { handleNewFamilySession } from './login.server.ts'

const LoginFormSchema = z.object({
	email: EmailSchema,
	redirectTo: z.string().optional(),
	remember: z.boolean().optional(),
})

export async function loader({ request }: LoaderFunctionArgs) {
	await requireFamilyAnonymous(request)
	return json({})
}

export async function action({ request }: ActionFunctionArgs) {
	await requireFamilyAnonymous(request)
	const formData = await request.formData()
	checkHoneypot(formData)
	const submission = await parseWithZod(formData, {
		schema: (intent) =>
			LoginFormSchema.transform(async (data, ctx) => {
				if (intent !== null) return { ...data, session: null }

				const session = await loginWithEmail(data)

				if (!session) {
					ctx.addIssue({
						code: z.ZodIssueCode.custom,
						message: 'Invalid email',
					})
					return z.NEVER
				}

				return { ...data, session }
			}),
		async: true,
	})

	if (submission.status !== 'success' || !submission.value.session) {
		return json(
			{ result: submission.reply() },
			{ status: submission.status === 'error' ? 400 : 200 },
		)
	}

	const { session, remember, redirectTo } = submission.value

	return handleNewFamilySession({
		request,
		session,
		remember: remember ?? false,
		redirectTo,
	})
}

export default function LoginPage() {
	const actionData = useActionData<typeof action>()
	const isPending = useIsPending()
	const [searchParams] = useSearchParams()
	const redirectTo = searchParams.get('redirectTo')

	const [form, fields] = useForm({
		id: 'login-form',
		constraint: getZodConstraint(LoginFormSchema),
		defaultValue: { redirectTo },
		lastResult: actionData?.result,
		onValidate({ formData }) {
			return parseWithZod(formData, { schema: LoginFormSchema })
		},
		shouldRevalidate: 'onBlur',
	})

	return (
		<div className="flex min-h-full flex-col justify-center pb-32 pt-20">
			<div className="mx-auto w-full max-w-md">
				<div className="flex flex-col place-items-center gap-0 text-center">
					<Logo />
					<Spacer size="4xs" />
					<Card className="mx-auto max-w-sm text-left">
						<CardHeader>
							<CardTitle className="text-2xl">Login</CardTitle>
							<CardDescription>
								Enter your email below to login to your account
							</CardDescription>
						</CardHeader>
						<CardContent>
							<Form method="POST" {...getFormProps(form)}>
								<HoneypotInputs />
								<div className="grid gap-2">
									<div className="grid gap-0">
										<Field
											labelProps={{ children: 'Email' }}
											inputProps={{
												...getInputProps(fields.email, { type: 'text' }),
												autoFocus: true,
												className: 'lowercase',
												autoComplete: 'email',
											}}
											errors={fields.email.errors}
										/>
										<CheckboxField
											labelProps={{
												htmlFor: fields.remember.id,
												children: 'Remember me',
											}}
											buttonProps={getInputProps(fields.remember, {
												type: 'checkbox',
											})}
											errors={fields.remember.errors}
										/>
										<input
											{...getInputProps(fields.redirectTo, { type: 'hidden' })}
										/>
										<ErrorList errors={form.errors} id={form.errorId} />
									</div>
									<div className="flex items-center justify-between gap-2">
										<StatusButton
											className="w-full"
											status={isPending ? 'pending' : form.status ?? 'idle'}
											type="submit"
											disabled={isPending}
										>
											Log in
										</StatusButton>
									</div>
								</div>
								<div className="mt-4 text-center text-sm">
									If you have difficulties please send our support team an email
									at{' '}
									<a className="underline" href="mailto:tech@jpgacademy.org">
										tech@jpgacademy.org
									</a>
								</div>
							</Form>
						</CardContent>
					</Card>
				</div>
			</div>
		</div>
	)
}

export const meta: MetaFunction = () => {
	return [{ title: 'Login to JPG Eats' }]
}

export function ErrorBoundary() {
	return <GeneralErrorBoundary />
}
